import gql from 'graphql-tag'
import { filtersTypes, stmtTypes } from '../lib/constants/template'

const generateFilterSelectionTypes = () => {
  let typeDefs = ''
  filtersTypes.forEach((filter) => {
    typeDefs += `
    extend type ${filter} {
      isSelected: Boolean!
    }
    `
  })
  return typeDefs
}

const generateStmtSelectionTypes = () => {
  let typeDefs = ''
  stmtTypes.forEach((phraseType) => {
    typeDefs += `
    extend type ${phraseType} {
      isSelected: Boolean!
    }
    `
  })
  return typeDefs
}

const typeDefs = gql`
  ${generateFilterSelectionTypes()}
  ${generateStmtSelectionTypes()}
  # eg:
  # extend type TemplateCategory {
  #   isSelected: Boolean!
  # }
  input TemplateConstructedStmtInput {
    actions: String
    useCases: String
    types: String
    minDuration: Int
    maxDuration: Int
  }
  # extend type Query:{
  #   isVideosOnly: Boolean
  # }
`

export default typeDefs
