/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient } from 'apollo-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import withApollo from 'next-with-apollo'
import { createHttpLink } from 'apollo-link-http'
import fetch from 'isomorphic-unfetch'
import { onError } from 'apollo-link-error'
import getConfig from 'next/config'
import { ApolloLink, split } from 'apollo-link'
import cookie from 'cookie'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { templateCacheDefaults } from '../lib/constants/template'
import introspectionQueryResultData from '../fragmentTypes.json'
import resolvers from './resolvers'
import typeDefs from './typeDefs'

const {
  publicRuntimeConfig: { apollo }
} = getConfig()
const { link } = apollo

const httpLink = createHttpLink({ fetch, ...link })

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    )
  }
  if (networkError) {
    console.log(
      `[Network error ${operation.operationName}]: ${networkError.message}`
    )
  }
})

/**
 * Get the user token from cookie
 * @param {Object} req
 */
const getToken = (req) => {
  const cookies = cookie.parse(req ? req.headers.cookie || '' : '')

  return cookies.token
}

const createAuthLink = (req) => {
  return new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from request.
    const token = getToken(req) || ''
    // Use the setContext method to set the HTTP headers.

    operation.setContext({
      headers: {
        cookie: `token=${token}`
      }
    })

    // Call the next link in the middleware chain.
    return forward(operation)
  })
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const [protocol, serverUrl] = link ? link.uri.split('://') : []
const socketUri = serverUrl || 'localhost:4444/gql'
const socketProtocol = protocol === 'https' ? 'wss' : 'ws'

const wsLink = process.browser
  ? new WebSocketLink({
      // if you instantiate in the server, the error will be thrown
      uri: `${socketProtocol}://${socketUri}`,
      options: {
        reconnect: true
      }
    })
  : null

export { getToken }
// Export a HOC from next-with-apollo
// Docs: https://www.npmjs.com/package/next-with-apollo
export default withApollo(
  // You can get headers and ctx (context) from the callback params
  // e.g. ({ headers, ctx, initialState })
  ({ ctx, initialState }) => {
    const authLink = createAuthLink(ctx ? ctx.req : '')

    const cache = new InMemoryCache({
      fragmentMatcher
    })
      //  rehydrate the cache using the initial data passed from the server:
      .restore(initialState || {})

    cache.writeData({
      data: {
        ...templateCacheDefaults
      }
    })

    return new ApolloClient({
      link: process.browser
        ? split(
            ({ query }) => {
              const def = getMainDefinition(query)
              return (
                def.kind === 'OperationDefinition' &&
                def.operation === 'subscription'
              )
            },
            wsLink,
            ApolloLink.from([errorLink, authLink, httpLink])
          )
        : ApolloLink.from([errorLink, authLink, httpLink]),
      connectToDevTools: true, // TODO: remove for production
      cache,
      resolvers,
      typeDefs
    })
  }
)
